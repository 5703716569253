@if (posts) {
  @switch (posts.level) {
    @case ('primary') {
      <div class="bp-posts-row bp-primary-post">
        <a class="bp-primary-img-link" [tdLink]="posts.posts[0].readPostLink()">
          <td-aspect-ratio ratio="16:9">
            <img [src]="posts.posts[0].imageURL" alt="" loading="lazy" class="bp-img" />
          </td-aspect-ratio>
        </a>
        <div class="bp-primary-post-summary">
          <td-blog-category-chips [post]="posts.posts[0]" />
          <a [tdLink]="posts.posts[0].readPostLink()">
            <h1 class="bp-post-title bp-primary-post-title">{{ posts.posts[0].title | plainText }}</h1>
          </a>
          <td-rich-text class="bp-primary-post-description" [value]="posts.posts[0].description" />
          <td-blog-authorship-bar [post]="posts.posts[0]" />
        </div>
      </div>
    }
    @case ('secondary') {
      <div class="bp-posts-row bp-secondary-posts">
        @for (post of posts.posts; track post) {
          @if (post) {
            <div class="bp-secondary-post">
              <a [tdLink]="post.readPostLink()">
                <td-aspect-ratio ratio="16:9"
                  ><img [src]="post.heroImageURL()" alt="" loading="lazy" class="bp-img"
                /></td-aspect-ratio>
              </a>
              <div class="bp-secondary-post-summary">
                <td-blog-category-chips [post]="post" />
                <a [tdLink]="post.readPostLink()">
                  <h2 class="bp-post-title">{{ post.title | plainText }}</h2>
                </a>
                <td-rich-text class="bp-secondary-post-description" [value]="post.description" />
                <td-blog-authorship-bar [post]="post" />
              </div>
            </div>
          }
        }
      </div>
    }
    @case ('tertiary') {
      <div class="bp-posts-row bp-tertiary-post">
        <div class="bp-img-tertiary-container">
          <a class="bp-tertiary-img-link" [tdLink]="posts.posts[0].readPostLink()">
            <td-aspect-ratio ratio="16:9" class="bp-img-tertiary">
              <img [src]="posts.posts[0].imageURL" class="bp-img" alt="" loading="lazy" />
            </td-aspect-ratio>
          </a>
        </div>
        <div class="bp-post-text-tertiary">
          <td-blog-category-chips [post]="posts.posts[0]" />
          <a [tdLink]="posts.posts[0].readPostLink()">
            <h3 class="bp-post-title">{{ posts.posts[0].title | plainText }}</h3>
          </a>
          <td-rich-text class="bp-tertiary-post-description" [value]="posts.posts[0].description" />
          <td-blog-authorship-bar [post]="posts.posts[0]" />
        </div>
      </div>
    }
  }
}

@if (resourcePanelsRow) {
  <div class="bp-resource-panels-row">
    <td-heading-with-highlights level="h4" [value]="resourcePanelsRow.title" />
    <td-resource-panels
      [cols]="3" [resources]="resourcePanelsRow.resources" [sectionId]="resourcePanelsRow.title.toPlainText()"
    />
  </div>
}
