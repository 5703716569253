<div class="sp-root">
  <td-illustration
    [id]="resizablePaneID"
    class="sp-pane sp-pane-resizable"
    [illustration]="panes.left"
    [visible]="visible"
  ></td-illustration>
  <img #sliderEl [src]="sliderImageSrc$ | async" alt="slider" class="cs-split-pane-slider" />
  <td-illustration class="sp-pane" [illustration]="panes.right" [visible]="visible"></td-illustration>
</div>
