<table class="section hide-mobile">
  <tr>
    @for (panel of panels; track panel) {
      <td>
        <div class="pp-cell-content">
          <h3>{{ panel.title }}</h3>
          <td-rich-text [value]="panel.body" class="pp-body-text" />
          <div class="flex"></div>
          <hr />
          <td-rich-text [value]="panel.secondaryBody" />
        </div>
      </td>
    }
  </tr>
  <tr class="pp-button-row">
    @for (panel of panels; track panel) {
      <td>
        <td-button [button]="panel.button" size="small" buttonWidth="100%" [linkId]="linkId(panel)" />
      </td>
    }
  </tr>
</table>
<table role="presentation" class="section hide-gt-mobile">
  @for (panel of panels; track panel) {
    <tr>
      <td>
        <div class="pp-cell-content">
          <h3>{{ panel.title }}</h3>
          <td-rich-text [value]="panel.body" class="pp-body-text" />
          <div class="flex"></div>
          <hr />
          <td-rich-text [value]="panel.secondaryBody" />
        </div>
      </td>
    </tr>
    <tr class="pp-button-row">
      <td><td-button [button]="panel.button" size="small" buttonWidth="100%" [linkId]="linkId(panel)" /></td>
    </tr>
  }
</table>
