<table class="section kpt-table">
  @for (keyPoint of keyPoints; track keyPoint) {
    <tr>
      <th>
        <div><img [src]="keyPoint.iconURL" alt="" /></div>
      </th>
      <td>
        <div>
          <h3>{{ keyPoint.title }}</h3>
          <td-rich-text [value]="keyPoint.body" />
        </div>
      </td>
    </tr>
  }
</table>
