@for (feature of featureRows.flat(); track feature) {
  <div class="fg-grid-item card-padding">
    <div class="fg-grid-item-text">
      @if (feature.title) {
        <div class="fg-feature-title-section">
          @if (feature.iconURL) {
            <img [src]="feature.iconURL" alt="" />
          }
          <h3>{{ feature.title }}</h3>
          <td-tag-chips [tags]="feature.tags" [sectionId]="sectionId" />
        </div>
      }
      @if (feature.body) {
        <td-rich-text [value]="feature.body" />
      }
      @if (feature.links?.length) {
        <div class="fg-links-section">
          @for (link of feature.links; track link) {
            <a class="link-tertiary" [tdLink]="link" [attr.id]="linkId(feature, link)">{{ link.text }}</a>
          }
        </div>
      }
    </div>
    @if (hasMediaIllustration(feature)) {
      <td-aspect-ratio ratio="16:9">
        <td-illustration [illustration]="$any(feature.illustration)" [isBlurred]="feature.isIllustrationBlurred" />
      </td-aspect-ratio>
    }
    @if (hasShortCodeSnippet(feature)) {
      <div class="fg-code-short-container">
        <pre
          class="fg-code-short"
          [class.fg-blur]="feature.isIllustrationBlurred"
        ><code [class]="'language-' + $any(feature.illustration).language">{{ $any(feature.illustration).code }}</code></pre>
        @if (!feature.isIllustrationBlurred) {
          <button
            class="fg-copy-button"
            title="Copy to clipboard"
            (click)="writeToClipboard($event, $any(feature.illustration).code)"
          >
            <div class="fg-copy-icon"></div>
            <span class="fg-copy-toast">Copied!</span>
          </button>
        }
      </div>
    }
    @if (hasCodeSnippetIllustration(feature)) {
      <td-illustration
        class="fg-code-snippet fg-code-snippet-short"
        [illustration]="$any(feature.illustration)"
        [isBlurred]="feature.isIllustrationBlurred"
      />
    }
  </div>
}

@if (illustration) {
  <div class="fg-illustration-row card-padding">
    <td-illustration [illustration]="illustration" />
  </div>
}
