@switch (displayAsLink) {
  @case (true) {
    <a [href]="person.linkedInURL" target="_blank" class="pi-person">
      <ng-container *ngTemplateOutlet="content" />
    </a>
  }
  @case (false) {
    <div class="pi-person">
      <ng-container *ngTemplateOutlet="content" />
    </div>
  }
}

<ng-template #content>
  <td-avatar [person]="person" [class]="avatarClass" />
  <div class="pi-person-text">
    <p class="pi-person-name" [class.text-p1]="variant === 'event'">
      <strong>{{ person.name }}</strong>
    </p>
    <aside class="pi-person-details">{{ person.jobTitle }}, {{ person.organisation.name }}</aside>
  </div>
</ng-template>
