
@switch (level) {
  @case ('h1') {
    <h1 [id]="id">
      <ng-container *ngTemplateOutlet="content; context: { value, themeColorHex }" />
    </h1>
  }
  @case ('h2') {
    <h2 [id]="id">
      <ng-container *ngTemplateOutlet="content; context: { value, themeColorHex }" />
    </h2>
  }
  @case ('h3') {
    <h3 [id]="id">
      <ng-container *ngTemplateOutlet="content; context: { value, themeColorHex }" />
    </h3>
  }
  @case ('h4') {
    <h4 [id]="id">
      <ng-container *ngTemplateOutlet="content; context: { value, themeColorHex }" />
    </h4>
  }
}

<ng-template #content>
  @for (span of value.spans; track span) {
    <span [ngStyle]="span.highlight ? { color: themeColorHex } : undefined">{{ span.text }}</span>
  }
</ng-template>
