<table class="section">
  <colgroup>
  @for (header of table.headerRow; track header) {
    <col />
  }
</colgroup>
<thead>
  <tr>
    @for (header of table.headerRow; track header) {
      <th class="text-p1">{{ header }}</th>
    }
  </tr>
</thead>
<tbody>
  @for (row of table.bodyRows; track row) {
    <tr>
      <th>
        <div class="ft-row-header">
          <span>{{ row.heading }}</span>
          @if (row.description) {
            <td-info-button [tooltipText]="row.description" />
          }
        </div>
      </th>
      @for (cell of row.cells; track cell) {
        <td><td-feature-table-cell [table]="table" [columnIndex]="$index" [cell]="cell" [sectionId]="sectionId" /></td>
      }
    </tr>
  }
</tbody>
</table>
