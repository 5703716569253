<div mat-dialog-title>
  <h1>Add to calendar</h1>
  <td-dialog-close-button />
</div>
<div mat-dialog-content>
  <td-actions [actions]="actions" sectionId="add-to-calendar-dialog" />
</div>
@if (isLoading) {
  <mat-progress-bar mode="indeterminate" />
}
