<div class="section tc-root">
  <div class="tc-carousel">
    @for (testimonial of testimonials; track testimonial; let idx = $index) {
      <div class="tc-card" [style.transform]="cardTransform(idx)">
        <div class="tc-card-content">
          <p>
            <em>{{ testimonial.body }}</em>
          </p>
          <div class="tc-details">
            <div class="tc-person">
              <img [src]="getHeadshotUrl(testimonial)" alt="" />
              <div>
                <p class="text-p1">
                  <strong>{{ testimonial.author.name }}</strong>
                </p>
                <aside class="tc-person-job">
                  {{ testimonial.author.jobTitle }},
                  {{ testimonial.author.organisation.name }}
                </aside>
              </div>
            </div>
            <img class="tc-logo" [src]="getLogoUrl(testimonial)" alt="" />
          </div>
        </div>
      </div>
    }
  </div>
  <div class="tc-control-panel">
    <button class="tc-controls-btn" (click)="previous()" [attr.id]="sectionId + '_carousel_previous'">
      <img src="/assets/image/chevron-left.svg" alt="<" />
    </button>
    <div class="tc-beads">
      @for (_ of testimonials; track _; let idx = $index) {
        <div class="tc-bead" [class.tc-bead-focused]="idx === focusedIndex"></div>
      }
    </div>
    <button class="tc-controls-btn" (click)="next()" [attr.id]="sectionId + '_carousel_next'">
      <img src="/assets/image/chevron-right.svg" alt=">" />
    </button>
  </div>
</div>
