@switch (cols) {
  @case (3) {
    <div class="section lc-root-cols-3">
      @for (resource of resources; track resource) {
        <a
          class="card lc-card lc-card-cols-3 lc-flex-container lc-card-compact"
          [attr.role]="resource.link ? undefined : 'button'"
          [attr.aria-disabled]="resource.link ? undefined : true"
          [class.hoverable]="!!resource.link"
          [tdLink]="resource.link"
          [attr.id]="panelID(resource)"
        >
          @if (!resource.link) {
            <aside class="lp-coming-soon">coming soon</aside>
          }
          <h3>{{ resource.title }}</h3>
          <td-rich-text [value]="resource.description" class="flex" />
          <p class="button-secondary">{{ resource.linkText }}</p>
        </a>
      }
    </div>
  }
  @case (4) {
    <div class="section lc-root-cols-4">
      @for (resource of resources; track resource) {
        <a
          [tdLink]="resource.link"
          class="card lc-card lc-flex-container lc-card-cols-4"
          [class.hoverable]="!!resource.link"
          [attr.id]="panelID(resource)"
        >
          @if (!resource.link) {
            <aside class="lp-coming-soon">coming soon</aside>
          }
          <h3>{{ resource.title }}</h3>
          <td-rich-text [value]="resource.description" class="flex" />
          <p class="link-tertiary">{{ resource.linkText }}</p>
        </a>
      }
    </div>
  }
}
