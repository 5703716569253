<div class="section ft-root">
  <td-scroll-shadow class="ft-tabs-container">
    <div class="ft-tabs">
      @for (tab of tabs; track tab) {
        <a
          class="ft-tab"
          [ngClass]="{ 'ft-tab-selected': (selectedTab$ | async) === tab }"
          [id]="tabID(tab)"
          [href]="'#' + tabID(tab)"
          (click)="onTabClick(tab, $event)"
        >
          <h5>{{ tab.title }}</h5>
        </a>
      }
    </div>
  </td-scroll-shadow>
  @for (tab of tabs; track tab) {
    <td-content-panel [panel]="tab" [panelId]="tabID(tab)" [hidden]="(selectedTab$ | async) !== tab" />
  }
</div>
