<div class="section ci-root">
  <div class="ci-illustration">
    <div class="ci-illustration-header"></div>
    <td-aspect-ratio ratio="16:9">
      <td-illustration class="il-image-illustration" [illustration]="illustration" [visible]="true" />
    </td-aspect-ratio>
    <div class="ci-illustration-footer"></div>
  </div>
  @if (caption) {
    <td-rich-text [value]="caption" class="text-p1" />
  }
</div>
