<div class="ft-root">
  <td-scroll-shadow class="ft-tabs-container">
    <div class="ft-tabs">
      @for (snippet of polyglotSnippet.snippets; track snippet) {
        <a
          class="ft-tab"
          [ngClass]="{ 'ft-tab-selected': (selectedSnippet$ | async) === snippet }"
          [id]="snippetTabID(snippet)"
          [href]="'#' + snippetTabID(snippet)"
          (click)="onSnippetTabClick(snippet, $event)"
          >
          <h5>{{ snippet.tabText || languageDisplayNames[snippet.language] }}</h5>
        </a>
      }
    </div>
  </td-scroll-shadow>
  @for (snippet of polyglotSnippet.snippets; track snippet) {
    <div class="ft-snippet-container" [hidden]="(selectedSnippet$ | async) !== snippet">
      <td-code-snippet [snippet]="snippet"/>
    </div>
  }
</div>
