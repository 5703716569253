<article tdPageBackground nebula="features" planet="yellow_green">
  @if (page$ | async; as page) {
    <div class="article-content">
      <td-title-body-actions-section
        [isIntroSection]="true" sectionId="intro"
        [title]="page.introSection.title" [body]="page.introSection.body" [actions]="page.introSection.actions"
      />
      @for (section of page.featureSections; track section; let idx = $index) {
        <td-features-page-technicolor-block [section]="section" [index]="idx"/>
        <td-feature-grid
          [layout]="section.featureGridLayout" [featureRows]="section.features" [illustration]="section.illustration"
          [sectionId]="section.sectionId"
        />
      }
      <td-features-page-technicolor-block [section]="page.finalSection" [index]="page.featureSections.length" />
      <td-conclusion-panel [panel]="page.finalSection.panel"/>
    </div>
  }
</article>
