<article tdPageBackground nebula="solutions">
  <div class="article-content article-content-no-planet">
    @if (post$ | async; as post) {
      <section class="page-section nebula-section">
        <a tdLink="/blog">
          @if (blog$ | async; as blog) {
            <td-heading-with-highlights
              [value]="blog.blogTitle"
              class="bp-site-title"
              level="h2"
              />
          }
        </a>
      </section>
      <div class="section-background-solid bp-post-section">
        <hr class="bp-intro-hr" />
        <nav td-blog-navbar variant="postPage" class="section"></nav>
        <section>
          <div class="bp-intro-section">
            <td-blog-category-chips [post]="post" />
            <td-heading-with-highlights level="h1" [value]="post.title" />
            <td-rich-text class="text-p1 bp-short-description" [value]="post.shortDescription" />
          </div>
          <div class="bp-author-section"><td-blog-authorship-bar [post]="post" size="medium" /></div>
          <hr class="bp-author-section-hr" />
        </section>
        <section class="bp-content-section">
          <div class="bp-content">
            <figure><img class="bp-featured-image" [src]="post.imageURL" alt="" /></figure>
            <td-rich-text class="text-blog" [value]="post.contentHtml" />
          </div>
          @if (post$ | async; as post) {
            <div class="bp-sidebar">
              <div class="bp-sharing-section">
                <h4>Share this article</h4>
                <div class="bp-sharing-links">
                  <a [href]="shareOnTwitterURL(post)" target="_blank">
                    <mat-icon svgIcon="twitter-rectangle" class="td-icon-xl" />
                  </a>
                  <a [href]="shareOnFacebookURL(post)" target="_blank">
                    <mat-icon svgIcon="facebook-rectangle" class="td-icon-xl" />
                  </a>
                  <a [href]="shareOnLinkedInURL(post)" target="_blank">
                    <mat-icon svgIcon="linkedin-rectangle" class="td-icon-xl" />
                  </a>
                  <a [href]="shareOnRedditURL(post)" target="_blank">
                    <mat-icon svgIcon="reddit-rectangle" class="td-icon-xl" />
                  </a>
                </div>
              </div>
              <div class="bp-newsletter-section">
                <h4>TypeDB Newsletter</h4>
                <p>Stay up to date with the latest TypeDB announcements and events.</p>
                <td-button [button]="subscribeToNewsletterButton" [linkId]="subscribeNewsletterLinkId(post)" />
              </div>
              @for (relatedPostGroup of relatedPostGroups$ | async; track relatedPostGroup) {
                @if (relatedPostGroup.posts.length) {
                  <aside>
                    <h4 class="bp-related-post-group-heading">
                      More on {{ categoryDisplayName(relatedPostGroup.categorySlug) }}
                    </h4>
                    @for (relatedPost of relatedPostGroup.posts; track relatedPost) {
                      <div class="bp-related-post">
                        <a [tdLink]="relatedPost.link">
                          <td-aspect-ratio ratio="16:9">
                            <img [src]="relatedPost.imageURL" alt="" class="bp-img" />
                          </td-aspect-ratio>
                        </a>
                        <h3 class="bp-related-post-title">
                          <a [tdLink]="relatedPost.link">{{ relatedPost.title }}</a>
                        </h3>
                        <td-rich-text class="bp-related-post-description" [value]="relatedPost.description" />
                        <aside class="bp-related-post-author">{{ relatedPost.author.name }}</aside>
                      </div>
                    }
                  </aside>
                }
              }
            </div>
          }
        </section>
        @if (post.furtherLearning) {
          <td-further-learning [value]="post.furtherLearning" />
        }
        <div class="subsection-margin"></div>
      </div>
    }
  </div>
</article>
