<article tdPageBackground nebula="cloud" planet="blue_pink">
  @if (page$ | async; as page) {
    <div class="article-content">
      <section class="page-section page-intro-section">
        <td-heading-with-highlights [value]="page.introTitle" level="h1" />
        <div class="rt-details subsection-margin">
          <div class="card rt-form-card">
            <h3><strong>Tell us about your organization</strong></h3>
            <div id="hubspot-form-holder"></div>
            <p>
              By submitting your personal data, you consent to emails from TypeDB. See our
              <a href="/legal/privacy-policy" target="_blank">Privacy Policy</a>.
            </p>
            @if (isSubmitting$ | async) {
              <mat-progress-bar mode="indeterminate" />
            }
          </div>
          <div class="rt-details-text">
            <td-p-with-highlights [value]="page.details.title" class="rt-details-title" level="p1" />
            @if (page.details.body) {
              <td-rich-text [value]="page.details.body" />
            }
          </div>
        </div>
      </section>
    </div>
  }
</article>
