@if (imageIllustration) {
  <img class="il-image" [src]="imageIllustration.url" [alt]="imageIllustration.altText" />
}
@if (videoEmbed) {
  <p>TODO: video embed with URL {{ videoEmbed.url }}</p>
}
@if (codeSnippet) {
  <td-code-snippet [snippet]="codeSnippet" />
}
@if (polyglotSnippet) {
  <td-polyglot-snippet [id]="polyglotSnippet.id" [snippet]="polyglotSnippet" />
}
@if (graphVisualisation && visible) {
  <td-graph-visualisation [graph]="graphVisualisation" />
}
@if (splitPaneIllustration) {
  <td-split-pane-illustration [panes]="splitPaneIllustration" [visible]="visible" />
}
