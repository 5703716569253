<div [id]="formHolderId"></div>
<div class="cp-contact-policy">
  <aside>
    By submitting your personal data, you consent to emails from TypeDB. See our
    <a href="/legal/privacy-policy" target="_blank">Privacy Policy</a>.
  </aside>
</div>
@if (isSubmitting$ | async) {
  <mat-progress-bar mode="indeterminate" />
}
