<div class="ftc-root" [ngClass]="{ 'ftc-unchecked': booleanValue === false }">
  @if (booleanValue === true) {
    <mat-icon svgIcon="check" class="td-icon-large" />
  }
  @if (stringValue) {
    <span>{{ stringValue }}</span>
  }
  @if (buttonValue) {
    <td-button [button]="buttonValue" buttonWidth="100%" [linkId]="buttonId()"/>
  }
</div>
