@for (item of items; track item) {
  @if (isContentRow(item)) {
    <div class="pp-content-row">
      @if (item.title) {
        <div>
          <h3>{{ item.title }}</h3>
        </div>
      }
      @if (item.item1 || item.item2) {
        <div class="pp-content-row-body">
          <ng-container *ngTemplateOutlet="contentRow; context: { item: item.item1 }" />
          <ng-container *ngTemplateOutlet="contentRow; context: { item: item.item2 }" />
        </div>
      }
    </div>
  }
  @if (isFeatureGrid(item)) {
    <td-feature-grid
      [disableCardAppearance]="true" [featureRows]="item.features" [layout]="item.featureGridLayout"
      [illustration]="item.illustration" [sectionId]="featureGridId(item)"
    />
  }
}

<ng-template #contentRow let-item="item">
  @if (!item) {
    <div></div>
  }
  @if (isPortableText(item)) {
    <td-rich-text [value]="item" />
  }
  @if (isIllustration(item)) {
    <td-illustration [illustration]="item" />
  }
</ng-template>
