export const HUBSPOT_PORTAL_ID = "4332244";
export const HUBSPOT_REGION = "na1";
export const GOOGLE_TAG_ID = "G-SNVZCNLJ9R"; // used by Google Analytics
export const GTM_ID = "GTM-WN8C98V";
export const googleAdsConversionIds = {
    subscribeToNewsletter: "AW-340366363/NZt8CPbH1PIYEJuopqIB",
    downloadPaper: "AW-340366363/jxpPCMG7mvUYEJuopqIB",
    joinCloudWaitlist: "AW-340366363/3eLoCMq7mvUYEJuopqIB",
    registerForLecture: "AW-340366363/cEfACMS7mvUYEJuopqIB",
    getInTouch: "AW-340366363/TDOzCPnH1PIYEJuopqIB",
    requestTechTalk: "AW-340366363/n5DQCMe7mvUYEJuopqIB",
    sendFeedback: "AW-340366363/u-TbCMuggvkYEJuopqIB",
} as const satisfies Record<string, string>;
