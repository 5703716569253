@if (nebula) {
  <div class="pb-container">
    <div class="pb-nebula" [ngClass]="getNebulaClass()">
      <picture>
        <source
          media="(max-width: 767px)"
          [srcset]="getNebulaSrc('mobile') + ', ' + getNebulaSrc('mobile', true) + ' 2x'"
        />
        <source
          media="(max-width: 1199px)"
          [srcset]="getNebulaSrc('tablet') + ', ' + getNebulaSrc('tablet', true) + ' 2x'"
        />
        <img [src]="getNebulaSrc('desktop')" [srcset]="getNebulaSrc('desktop', true) + ' 2x'" alt="" />
      </picture>
    </div>
  </div>
}
@if (planet) {
  <div class="pb-container">
    <div class="pb-planet">
      <picture>
        <source
          media="(max-width: 767px)"
          [srcset]="getPlanetSrc('mobile') + ', ' + getPlanetSrc('mobile', true) + ' 2x'"
        />
        <source
          media="(max-width: 1199px)"
          [srcset]="getPlanetSrc('tablet') + ', ' + getPlanetSrc('tablet', true) + ' 2x'"
        />
        <img [src]="getPlanetSrc('desktop')" [srcset]="getPlanetSrc('desktop', true) + ' 2x'" alt="" />
      </picture>
    </div>
  </div>
}
<ng-content/>
