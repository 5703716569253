<div class="section cp-root">
  <div class="cp-main-area">
    <h3>{{ panel.title }}</h3>
    @if (panel.body) {
      <td-rich-text [value]="panel.body" />
    }
    <div class="flex"></div>
    <td-actions [actions]="panel.actions" [sectionId]="sectionId" />
  </div>
  <div class="cp-resources-area">
    <h4>{{ panel.resourceListTitle }}</h4>
    <ul>
      @for (resource of panel.resources; track resource) {
        <li>
          <a [tdLink]="resource" class="link-tertiary" [attr.id]="resourceLinkId(resource)">{{ resource.text }}</a>
        </li>
      }
    </ul>
  </div>
</div>
